import { Placeholder } from '@components/Placeholder';
import { ReactNode } from 'react';
import components, { getComponent } from '..';

export interface DynamicComponentProps {
    blok?: Blok | Blok[];
}

export function DynamicComponent({ blok }: DynamicComponentProps): ReactNode {
    if (Array.isArray(blok)) {
        return blok.map((child: Blok) => <DynamicComponent blok={child} key={child._uid} />);
    }

    if (blok && blok.component && components[blok.component]) {
        const Component = getComponent(blok.component);
        return <Component blok={blok} />;
    }

    return <Placeholder blok={blok} />;
}

export default DynamicComponent;
