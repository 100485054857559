// 'use client';

import { BlokLink } from '@components/storyblok/BlokLink';
import { storyblokEditable } from '@storyblok/react';
import { SbStoryLink } from '@utils/storyblok/types';
import classNames from 'classnames';
import { Button as BsButton } from 'react-bootstrap';
import { DynamicComponent } from '../DynamicComponent';

export interface ButtonProps {
    content?: string;
    children?: Blok[];
    link?: SbStoryLink;
    size?: 'lg' | 'sm' | 'xs';
    variant?: string;
    target?: string;
    className?: string;
}

export type ButtonBlok = Blok & ButtonProps;

export const Button: BlokFC<ButtonBlok> = ({ blok }) => {
    const { content, children, link, size, variant, className } = blok;

    const button = (
        <BsButton
            as="span"
            {...storyblokEditable(blok)}
            key={blok._uid}
            className={classNames(className, size && `btn-${size}`)}
            variant={variant}
        >
            <>
                {children && <DynamicComponent blok={children} />}
                {content}
            </>
        </BsButton>
    );

    return <BlokLink link={link}>{button}</BlokLink>;
};

export default Button;
