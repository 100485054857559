import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { Row } from 'react-bootstrap';
import { Column, ColumnBlok } from './Column';

export interface GridBlok extends Blok {
    columns: ColumnBlok[];
    className?: string;
    id?: string;
}

export const Grid: BlokFC<GridBlok> = ({ blok }) => {
    return (
        <Row id={blok.id || ''} {...storyblokEditable(blok)} key={blok._uid} className={classNames(blok.className)}>
            {blok.columns.map((column: ColumnBlok) => {
                return <Column key={column._uid} blok={column}></Column>;
            })}
        </Row>
    );
};

export default Grid;
