import { DynamicComponent } from '@components/bloks/DynamicComponent';
import { BlokLink } from '@components/storyblok/BlokLink';
import { storyblokEditable } from '@storyblok/react';
import { isValidLink } from '@utils/storyblok/link';
import { SbStoryLink } from '@utils/storyblok/types';
import classNames from 'classnames';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'react-bootstrap';
import { FaAngleRight } from 'react-icons/fa';

export type CardButtonJustification = 'center' | 'right';
export interface CardButtonBlokProps {
    title?: Blok[];
    children?: Blok[];
    className?: string;
    link?: SbStoryLink;
    linkText?: string;
    altText?: string;
    justify?: CardButtonJustification;
}

export type CardButtonBlok = Blok & CardButtonBlokProps;

export const CardButton: React.FC<BlokComponentProps<CardButtonBlok>> = ({ blok }) => {
    const { title, children, altText, link, linkText, justify } = blok;
    const isLinkValid = !!link && isValidLink(link);

    let content = (
        <Card key={blok._uid} className={classNames(`justify-${justify || 'right'}`)}>
            <CardBody>
                <Row>
                    <Col>
                        <DynamicComponent blok={title} />
                        <DynamicComponent blok={children} />
                        {isLinkValid && (
                            <Button
                                as="span"
                                className="floating-button btn-xs"
                                variant="dark"
                                title={altText || 'Go to link'}
                            >
                                {linkText}
                                <FaAngleRight></FaAngleRight>
                            </Button>
                        )}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

    if (isLinkValid) {
        content = <BlokLink link={link}>{content}</BlokLink>;
    }

    const className = classNames(blok.className, 'card-button', isLinkValid ? 'linked' : null);

    return (
        <div data-testid="card-button" {...storyblokEditable(blok)} className={className}>
            {content}
        </div>
    );
};

export default CardButton;
