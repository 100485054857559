import { DynamicComponent } from '@components/bloks/DynamicComponent';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export type ServicePosition = 'left' | 'right';
export interface ServiceBlokProps {
    title?: Blok[];
    titleChildren?: Blok[];
    children?: Blok[];
    className?: string;
    id?: string;
    variant?: string;
    linkName?: string;
}

export type ServiceBlok = Blok & ServiceBlokProps;

interface TitleProps {
    title?: Blok[];
    titleChildren?: Blok[];
    reverse: boolean;
}

const Title: React.FC<TitleProps> = ({ title, titleChildren, reverse }) => {
    const children = [
        <Col key="title" className="title" md={12} lg={4} xl={4}>
            <DynamicComponent blok={title} />
        </Col>,
        <Col key="children" className="title-children align-self-end" md={12} lg={8} xl={8}>
            <DynamicComponent blok={titleChildren} />
        </Col>,
    ];

    if (reverse) {
        children.reverse();
    }

    return <Row className="title-section">{children}</Row>;
};

export interface ServiceProps extends BlokComponentProps<ServiceBlok> {
    position: ServicePosition;
}

export const Service: React.FC<ServiceProps> = ({ blok, position }) => {
    const className = classNames(
        'service',
        `service-${position}`,
        blok.variant && `service-bg-${blok.variant}`,
        blok.className,
    );
    const { title, titleChildren, children, id } = blok;

    return (
        <section id={id || ''} data-testid="service" {...storyblokEditable(blok)} className={className}>
            <Container>
                <Title title={title} titleChildren={titleChildren} reverse={position === 'right'} />
                <Row className="pt-5">
                    <Col>
                        <DynamicComponent blok={children} />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Service;
