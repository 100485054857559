'use client';
import React, { FC, PropsWithChildren, useState } from 'react';

interface IThemeContext {
    isEditMode: boolean;
    enableEditMode: () => void;
}

const defaultState = {
    isEditMode: false,
    enableEditMode: (): void => void 0,
};

export const StoryblokContext = React.createContext<IThemeContext>(defaultState);

export const StoryblokProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isEditMode, setIsEditMode] = useState(defaultState.isEditMode);

    const enableEditMode = (): void => {
        setIsEditMode(true);
    };

    return (
        <StoryblokContext.Provider
            value={{
                isEditMode: isEditMode,
                enableEditMode,
            }}
        >
            {children}
        </StoryblokContext.Provider>
    );
};
