import { DynamicComponent } from '@components/bloks/DynamicComponent';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';

export interface SectionProps {
    children?: Blok[];
    variant?: string;
    className?: string;
}

export type SectionBlok = Blok & SectionProps;

export const Section: BlokFC<SectionBlok> = ({ blok }) => {
    const { variant } = blok;
    const className = classNames(variant && `section-bg-${variant}`, blok.className);
    return (
        <section data-testid="section" {...storyblokEditable(blok)} key={blok._uid} className={className}>
            {blok.children && <DynamicComponent blok={blok.children} />}
        </section>
    );
};

export default Section;
