import { DynamicComponent } from '@components/bloks/DynamicComponent';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { Container as BsContainer } from 'react-bootstrap';

export interface ContainerBlok extends Blok {
    children?: Blok[];
    fluid?: boolean;
    className?: string;
    id?: string;
}

export const Container: BlokFC<ContainerBlok> = ({ blok }) => {
    return (
        <BsContainer
            id={blok.id || ''}
            className={classNames(blok.className)}
            data-testid="container"
            {...storyblokEditable(blok)}
            key={blok._uid}
            fluid={blok.fluid}
        >
            <DynamicComponent blok={blok.children} key={blok._uid} />
        </BsContainer>
    );
};

export default Container;
