import { DynamicComponent } from '@components/bloks/DynamicComponent';
import { storyblokEditable } from '@storyblok/react';

export interface PageBlok extends Blok {
    children?: Blok[];
    title?: string;
    description?: string;
    className?: string;
}

export const Page: BlokFC<PageBlok> = ({ blok }) => {
    const { className, children } = blok;
    return (
        <>
            <main {...storyblokEditable(blok)} key={blok._uid} className={className}>
                <DynamicComponent blok={children} />
            </main>
        </>
    );
};

export default Page;
