import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1_sass@1.76.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1_sass@1.76.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1/node_modules/react-bootstrap/esm/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1/node_modules/react-bootstrap/esm/Card.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1/node_modules/react-bootstrap/esm/CardBody.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1/node_modules/react-bootstrap/esm/Col.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1/node_modules/react-bootstrap/esm/NavLink.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/src/components/bloks/ContactForm/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/src/components/bloks/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactButton"] */ "/vercel/path0/ui/src/components/bloks/MePage/ContactButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VCardButton"] */ "/vercel/path0/ui/src/components/bloks/MePage/VCardButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/src/components/StoryblokBridge/index.tsx");
