import Approaches from '@components/bloks/Approaches';
import Approach from '@components/bloks/Approaches/Approach';
import Button from '@components/bloks/Button';
import CardButtons from '@components/bloks/CardButtons';
import CardButton from '@components/bloks/CardButtons/CardButton';
import ContactForm from '@components/bloks/ContactForm';
import Container from '@components/bloks/Container';
import Grid from '@components/bloks/Grid';
import Header from '@components/bloks/Header';
import Image from '@components/bloks/Image';
import MePage from '@components/bloks/MePage';
import NavLink from '@components/bloks/NavLink';
import Page from '@components/bloks/Page';
import RichText from '@components/bloks/RichText';
import Section from '@components/bloks/Section';
import Services from '@components/bloks/Services';
import Service from '@components/bloks/Services/Service';

interface Components {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: React.FunctionComponent<any>;
}

const components: Components = {
    approach: Approach,
    approaches: Approaches,
    button: Button,
    'card-button': CardButton,
    'card-buttons': CardButtons,
    'contact-form': ContactForm,
    container: Container,
    'error-page': Page,
    grid: Grid,
    header: Header,
    image: Image,
    'me-page': MePage,
    navlink: NavLink,
    page: Page,
    section: Section,
    service: Service,
    services: Services,
    text: RichText,
};

export const getComponent = (name: string) => {
    return components[name];
};

export default components;
