'use client';
import { loadStoryblokBridge, SbBlokData } from '@storyblok/js';
import { StoryblokContext } from '@utils/storyblok/StoryblokContext';
import { ReactNode, useContext, useState } from 'react';
import DynamicComponent from '../bloks/DynamicComponent';

const StoryblokBridge = ({ blok }: { blok?: SbBlokData }): ReactNode => {
    const { enableEditMode } = useContext(StoryblokContext);
    const [blokState, setBlokState] = useState(blok);

    loadStoryblokBridge()
        .then(() => {
            const { StoryblokBridge, location } = window;
            const storyblokInstance = new StoryblokBridge();

            storyblokInstance.on(['enterEditmode'], () => {
                console.log('Entering edit mode');
                enableEditMode();
            });

            storyblokInstance.on(['published', 'change'], () => {
                location.reload();
            });

            storyblokInstance.on(['input'], (e) => {
                setBlokState(e?.story?.content as SbBlokData);
            });
        })
        .catch((err) => console.error(err));

    return <DynamicComponent blok={blokState} />;
};

export default StoryblokBridge;
