import { storyblokEditable } from '@storyblok/react';
import { SbImage } from '@utils/storyblok/types';
import classNames from 'classnames';
import NextImage, { ImageProps } from 'next/image';

const DEFAULT_HEIGHT: ImageProps['height'] = '100';
const DEFAULT_WIDTH: ImageProps['width'] = '100';
const DEFAULT_PLACEHOLDER_BLUR_RADIUS = 15;

export interface ImageBlok extends Blok {
    image?: SbImage;
    url?: string;
    width?: ImageProps['width'];
    height?: ImageProps['height'];
    fill?: ImageProps['fill'];
    quality?: ImageProps['quality'];
    priority?: ImageProps['priority'];
    sizes?: ImageProps['sizes'];
    placeholderWidth?: number;
    placeholderHeight?: number;
    placeholderBlurRadius?: number;
    className?: string;
}

const generatePlaceholderUrl = (src: string, width?: number, height?: number, radius?: number): string | undefined => {
    if (width && height) {
        return `${src}/m/${width}x${height}/filters:blur(${radius || DEFAULT_PLACEHOLDER_BLUR_RADIUS})`;
    }
    return undefined;
};

export const Image: BlokFC<ImageBlok> = ({ blok }) => {
    const src = blok.image?.id ? blok.image.filename : blok.url;

    if (src) {
        const { placeholderWidth, placeholderHeight, placeholderBlurRadius } = blok;

        const blurDataUrl = generatePlaceholderUrl(src, placeholderWidth, placeholderHeight, placeholderBlurRadius);
        return (
            <div className={classNames('image-container', blok.fill === true && 'fill')}>
                <NextImage
                    src={src}
                    width={blok.fill === true ? undefined : blok.width || DEFAULT_WIDTH}
                    height={blok.fill === true ? undefined : blok.height || DEFAULT_HEIGHT}
                    fill={blok.fill}
                    quality={blok.quality}
                    priority={blok.priority}
                    sizes={blok.sizes}
                    placeholder={blurDataUrl ? 'blur' : 'empty'}
                    blurDataURL={blurDataUrl}
                    {...storyblokEditable(blok)}
                    key={blok._uid}
                    className={blok.className}
                    alt={blok.image?.alt || ''}
                    title={blok.image?.title}
                />
            </div>
        );
    }

    return null;
};

export default Image;
