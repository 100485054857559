import { DynamicComponent } from '@components/bloks/DynamicComponent';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { Col } from 'react-bootstrap';

export interface ColumnBlok extends Blok {
    children?: Blok[];
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
    className?: string;
    id?: string;
}

export const Column: BlokFC<ColumnBlok> = ({ blok: column }) => {
    const props = {
        xs: column.xs,
        sm: column.sm,
        md: column.md,
        lg: column.lg,
        xl: column.xl,
        xxl: column.xxl,
    };

    const containerClass = classNames(column.className);

    return (
        <Col
            id={column.id || ''}
            {...storyblokEditable(column)}
            key={column._uid}
            {...props}
            className={containerClass}
        >
            <DynamicComponent blok={column.children} />
        </Col>
    );
};
