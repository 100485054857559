export const clamp = (value: number, min: number, max: number): number => {
    return Math.max(min, Math.min(value, max));
};

export const randomInt = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min)) + min;
};

export const randomBool = (): boolean => {
    return Math.random() < 0.5;
};
