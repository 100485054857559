import { BlokLink } from '@components/storyblok/BlokLink';
import { storyblokEditable } from '@storyblok/react';
import { SbStoryLink } from '@utils/storyblok/types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { MARK_LINK, render, StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { DynamicComponent } from '../DynamicComponent';

export type TextJustifications = 'center' | 'end' | 'start';

type JustificationClasses = 'text-center' | 'text-end' | 'text-start';

export interface RichTextProps {
    text?: StoryblokRichtext;
    simpleText?: string;
    className?: string;
    justify?: TextJustifications;
    id?: string;
}

export type RichTextBlok = Blok & RichTextProps;

const JUSTIFICATIONS: { [key in TextJustifications]: JustificationClasses } = {
    start: 'text-start',
    center: 'text-center',
    end: 'text-end',
};

const getTextJustification = (justification: TextJustifications | undefined): JustificationClasses | null => {
    if (justification && justification in JUSTIFICATIONS) {
        return JUSTIFICATIONS[justification];
    }
    return null;
};

export const RichText: BlokFC<RichTextBlok> = ({ blok }) => {
    const { text, simpleText, justify, id } = blok;
    const containerClass = classNames(getTextJustification(justify));
    return (
        <div id={id} data-testid="rich-text" {...storyblokEditable(blok)} key={blok._uid} className={containerClass}>
            <div className={`mx-auto ${blok.className || ''}`}>
                {!!simpleText
                    ? simpleText
                    : render(text, {
                          defaultBlokResolver: (name, props) => (
                              <DynamicComponent blok={{ ...(props as Blok), component: name }} />
                          ),
                          markResolvers: {
                              [MARK_LINK]: (children, link) => {
                                  return (
                                      <BlokLink link={link as SbStoryLink} data-testid="marked-link">
                                          <Button variant="link">{children}</Button>
                                      </BlokLink>
                                  );
                              },
                          },
                      })}
            </div>
        </div>
    );
};

export default RichText;
