import { DynamicComponent } from '@components/bloks/DynamicComponent';
import { BlokLink } from '@components/storyblok/BlokLink';
import { storyblokEditable } from '@storyblok/react';
import { SbStoryLink } from '@utils/storyblok/types';
import classNames from 'classnames';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

export type ApproachVariant = 'left' | 'right';
export interface ApproachBlokProps {
    title?: string;
    titleChildren?: Blok[];
    children?: Blok[];
    className?: string;
    id?: string;
    buttonText?: string;
    buttonLink?: SbStoryLink;
    linkName?: string;
}
export type ApproachBlok = ApproachBlokProps & Blok;

export interface ApproachProps extends BlokComponentProps<ApproachBlok> {
    variant: ApproachVariant;
}

export const Approach: React.FC<ApproachProps> = ({ blok, variant }) => {
    const className = classNames(
        blok.className,
        'approach',
        `approach-${variant}`,
        'col-lg-10',
        'col-md-10',
        variant === 'right' ? 'offset-md-0 offset-lg-0' : 'offset-md-2 offset-lg-2',
    );
    const { title, children, buttonText, buttonLink } = blok;

    return (
        <Row id={blok.id} data-testid="approach" {...storyblokEditable(blok)} className={className}>
            <div className="pill" key="title">
                {title}
            </div>
            <div className="arrow" key="arrow"></div>
            <Col>
                <Container fluid>
                    <Row className="pt-5">
                        <Col>
                            <DynamicComponent blok={children} />
                        </Col>
                    </Row>
                </Container>
            </Col>
            {buttonText && buttonLink && (
                <BlokLink link={buttonLink}>
                    <Button variant="secondary" className={`${variant === 'right' ? 'end' : 'start'}-button`}>
                        {buttonText}
                    </Button>
                </BlokLink>
            )}
        </Row>
    );
};

export default Approach;
