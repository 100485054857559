import { routes } from '@constants/routes';
import { LINKS_STARTS_WITH_ME_PAGE } from '@constants/storyblok';
import { linkGitHub, linkLinkedIn } from '@utils/socialLinks';
import { SbStoryLink } from '@utils/storyblok/types';
import classNames from 'classnames';
import Link from 'next/link';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { AiOutlineMail } from 'react-icons/ai';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { ContactForm } from '../ContactForm';
import { DynamicComponent } from '../DynamicComponent';
import { ContactButton } from './ContactButton';
import { VCardButton } from './VCardButton';

export interface MePageBlok extends Blok {
    profileImage?: Blok[];
    firstName?: string;
    lastName?: string;
    role?: string;
    location?: string;
    about?: Blok[];
    email?: string;
    linkedInUser?: string;
    githubUser?: string;
    backgroundTheme?: string;
    self?: SbStoryLink;
}

const mailTo = (email?: string): string => {
    return email ? `mailto:${email};` : '';
};

const sanitizedFullName = (firstName?: string, lastName?: string): string => {
    if (firstName) {
        let fullName: string = firstName.trim();

        if (lastName) {
            fullName += ` ${lastName.trim()}`;
        }

        return fullName;
    }

    return '';
};

export const MePage: BlokFC<MePageBlok> = ({ blok }) => {
    const {
        firstName,
        lastName,
        role,
        location,
        profileImage,
        email,
        linkedInUser,
        githubUser,
        about,
        backgroundTheme,
        self,
    } = blok;

    const fullName = sanitizedFullName(firstName, lastName);

    const emailAsMailto = mailTo(email);

    const re = new RegExp(`^(${LINKS_STARTS_WITH_ME_PAGE})`, 'g');
    const selfSlug = (self?.story?.slug || self?.story?.full_slug)?.replace(re, '');

    return (
        <>
            <section data-testid="mepage" className={classNames('py-5', `section-bg-${backgroundTheme || 'light'}`)}>
                <Container className="me-page">
                    <Row className="text-center">
                        <Col className="offset-md-2 offset-lg-3" sm={12} md={8} lg={6}>
                            {email && firstName ? (
                                <>
                                    <div className="profile-picture">
                                        {profileImage && <DynamicComponent blok={profileImage} />}
                                    </div>
                                    <h1 className="name">{fullName}</h1>
                                    <h2 className="role mt-2">
                                        {role}
                                        {location && `, ${location}`}
                                    </h2>

                                    <div className="links d-grid gap-2">
                                        {selfSlug && (
                                            <VCardButton buttonProps={{ className: 'mt-1 mb-4' }} meSlug={selfSlug}>
                                                Add me to your contacts (vcard)
                                            </VCardButton>
                                        )}

                                        {email && (
                                            <ContactButton
                                                variant="link"
                                                Icon={<AiOutlineMail />}
                                                href={emailAsMailto}
                                                target="_blank"
                                                copyToClipboard
                                            >
                                                {email}
                                            </ContactButton>
                                        )}

                                        {linkedInUser && (
                                            <ContactButton
                                                href={linkLinkedIn(linkedInUser)}
                                                variant="link"
                                                Icon={<FaLinkedin />}
                                                target="_blank"
                                                copyToClipboard={linkedInUser}
                                            >
                                                <strong>LinkedIn</strong> {linkedInUser}
                                            </ContactButton>
                                        )}

                                        {githubUser && (
                                            <ContactButton
                                                href={linkGitHub(githubUser)}
                                                variant="link"
                                                Icon={<FaGithub />}
                                                target="_blank"
                                                copyToClipboard={githubUser}
                                            >
                                                <strong>GitHub</strong> {githubUser}
                                            </ContactButton>
                                        )}
                                    </div>
                                    {about && (
                                        <div className="about pt-4">
                                            <DynamicComponent blok={about} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <h1 className="h3">Sorry, I don&apos;t have a contact page yet!</h1>
                                    <p>
                                        You can get in touch with us using the{' '}
                                        <Link href="#contact-us" passHref>
                                            <Button variant="link">contact form</Button>
                                        </Link>{' '}
                                        or return to our{' '}
                                        <Link href={routes.home} passHref>
                                            <Button variant="link">home page</Button>
                                        </Link>
                                        .
                                    </p>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="py-5" id="contact-us">
                <Container>
                    <div className="text-center">
                        <h1>Get in touch</h1>
                        <p>
                            Please send us a message below or email us at{' '}
                            <Link href="mailto:contact@bigbyte.digital" passHref>
                                <Button variant="link">contact@bigbyte.digital</Button>
                            </Link>
                            . We&apos;d love to talk!
                        </p>
                    </div>
                    <Col className="offset-md-1" md={10}>
                        <ContactForm blok={{ messageRows: 8 }}></ContactForm>
                    </Col>
                </Container>
            </section>
        </>
    );
};

export default MePage;
