import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { Button, Container } from 'react-bootstrap';
import { Service, ServiceBlok } from './Service';

export interface ServicesProps {
    children?: ServiceBlok[];
    showLinks?: boolean;
}

export type ServicesBlok = Blok & ServicesProps;

export const Services: BlokFC<ServicesBlok> = ({ blok }) => {
    const className = classNames('services');
    const { children: services, showLinks } = blok;
    return (
        <div data-testid="services" {...storyblokEditable(blok)} className={className}>
            {showLinks && (
                <Container data-testid="service-links" className="service-links text-center">
                    {services?.map(
                        (service, index) =>
                            service?._uid &&
                            service?.linkName && (
                                <span className="service-link" key={`service-${service._uid}-link`}>
                                    {index != 0 && ' | '}
                                    <Button variant="link" href={`#${service.id || ''}`}>
                                        {service?.linkName?.trim()}
                                    </Button>
                                </span>
                            ),
                    )}
                </Container>
            )}
            {services?.map((service, index) => (
                <Service key={service._uid} blok={service} position={index % 2 ? 'right' : 'left'} />
            ))}
        </div>
    );
};

export default Services;
