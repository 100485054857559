import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { Approach, ApproachBlok } from './Approach';

export interface ApproachesProps {
    children?: ApproachBlok[];
    showLinks?: boolean;
}

export type ApproachesBlok = ApproachesProps & Blok;

export const Approaches: BlokFC<ApproachesBlok> = ({ blok }) => {
    const className = classNames('approaches');
    const { children: approaches, showLinks } = blok;

    return (
        <div data-testid="approaches" {...storyblokEditable(blok)} className={className}>
            <>
                {showLinks && (
                    <Container data-testid="approach-links" className="approach-links text-center">
                        {approaches?.map(
                            (approach, index) =>
                                approach._uid &&
                                approach?.linkName && (
                                    <span className="approach-link" key={`${approach._uid}-approach-link`}>
                                        {index != 0 && ' → '}
                                        <Button variant="link" href={`#${approach.id || ''}`}>
                                            {approach?.linkName?.trim()}
                                        </Button>
                                    </span>
                                ),
                        )}
                    </Container>
                )}
            </>

            <Container fluid className="approaches">
                {approaches?.map(
                    (approach, index) =>
                        approach._uid &&
                        blok._uid && (
                            <React.Fragment key={`approach-${blok._uid}-${approach._uid}`}>
                                <Approach blok={approach} variant={index % 2 ? 'right' : 'left'} />
                            </React.Fragment>
                        ),
                )}
            </Container>
        </div>
    );
};

export default Approaches;
