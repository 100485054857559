export const CACHE_SLUG_ALL_STORIES = 'page-stories';
export const CACHE_SLUG_LAYOUT_STORIES = 'layout-stories';
export const CACHE_SLUG_ALL_LINKS = 'page-links';
export const CACHE_SLUG_ALL_ME_PAGES = 'me-page-stories';

export const STORY_SLUG_NAVIGATION = 'global/navigation/navigation';
export const STORY_SLUG_FOOTER = 'global/footer/footer';
export const STORY_SLUG_ERROR_404 = 'pages/error/404';

export const LINKS_STARTS_WITH_PAGE = 'pages/';
export const LINKS_STARTS_WITH_ME_PAGE = `${LINKS_STARTS_WITH_PAGE}me/`;

export const COMPONENT_TYPE_PAGE = 'page';
export const COMPONENT_TYPE_ME_PAGE = 'me-page';
export const COMPONENT_TYPE_ERROR_PAGE = 'error-page';

export const DEFAULT_GLOBAL_STORY_COMPONENTS: string[] = ['navigation', 'footer', 'error-page'];
