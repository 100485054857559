import { isDevelopment, isDraft } from '@utils';
import classNames from 'classnames';
import React from 'react';

export interface PlaceholderProps {
    blok?: Blok;
}

export const Placeholder: React.FC<PlaceholderProps> = ({ blok }) => {
    const componentType = blok?.component;

    const shouldShowPlaceholder = isDevelopment() || isDraft();

    if (shouldShowPlaceholder) {
        return (
            <div
                data-testid="placeholder"
                className={classNames(
                    'placeholder',
                    'w-100',
                    'p-4',
                    'm-4',
                    'border',
                    'border-danger',
                    'bg-light',
                    'border-3',
                    'rounded',
                    'text-black',
                    'text-start',
                )}
            >
                <p data-testid="not-created-text" className="text-red fst-italic">
                    The component <strong>{componentType || '[Invalid Blok]'}</strong> has not been created yet.
                </p>

                <pre className="text-start">blok = {blok ? JSON.stringify(blok, null, 4) : 'undefined'}</pre>
            </div>
        );
    }
    return undefined;
};

export default Placeholder;
