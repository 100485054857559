import { storyblokEditable } from '@storyblok/react';
import { clamp } from '@utils/math';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import CardButton from './CardButton';

const MAXIMUM_COLUMNS = 12;
const DEFAULT_CARDS_XL = 4;
const DEFAULT_CARDS_LG = 3;
const DEFAULT_CARDS_MD = 2;
const DEFAULT_CARDS = 1;

export interface CardButtonsProps {
    children?: Blok[];
    cardsXl?: number;
    cardsLg?: number;
    cardsMd?: number;
    cardsSm?: number;
}

export type CardButtonsBlok = Blok & CardButtonsProps;

const cardsToColumn = (cardsPerColumn: number): number => {
    const colSize = MAXIMUM_COLUMNS / clamp(cardsPerColumn, 1, 12);
    return colSize;
};

export const CardButtons: BlokFC<CardButtonsBlok> = ({ blok }) => {
    const { children, cardsXl, cardsLg, cardsMd, cardsSm } = blok;

    if (children?.length === 0) {
        return null;
    }

    const className = classNames('card-buttons', 'g-3');
    return (
        <Row {...storyblokEditable(blok)} key={blok._uid} className={className}>
            {children?.map((card) => (
                <Col
                    key={card._uid}
                    sm={cardsToColumn(cardsSm || DEFAULT_CARDS)}
                    md={cardsToColumn(cardsMd || DEFAULT_CARDS_MD)}
                    lg={cardsToColumn(cardsLg || DEFAULT_CARDS_LG)}
                    xl={cardsToColumn(cardsXl || DEFAULT_CARDS_XL)}
                >
                    <CardButton blok={card} />
                </Col>
            ))}
        </Row>
    );
};

export default CardButtons;
